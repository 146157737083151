import {
  createRouter as createVueRouter,
  createWebHashHistory,
  Router,
} from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import ExternalApi from "../views/ExternalApi.vue";
import Convert2dto3d from "../views/2dto3d.vue";
import { createAuthGuard } from "@auth0/auth0-vue";
import { App } from "vue";

export function createRouter(app: App): Router {
  return createVueRouter({
    routes: [
      {
        path: "/",
        name: "home",
        component: Convert2dto3d,
      },
      {
        path: "/profile",
        name: "profile",
        component: Profile,
        beforeEnter: createAuthGuard(app),
      },
      {
        path: "/external-api",
        name: "external-api",
        component: ExternalApi,
        beforeEnter: createAuthGuard(app),
      },
      {
        path: "/2dto3d",
        name: "2dto3d",
        component: Convert2dto3d,
        beforeEnter: createAuthGuard(app),
      },
    ],
    history: createWebHashHistory(),
  });
}

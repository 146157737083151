<template>
  <div class="nav-container mb-3">
    <nav class="navbar navbar-expand-md border-navbarBg bg-navbarBg">
      <div class="container">
        <div
          class="navbar-brand gap-12 ml-4 mr-4"
          style="align-items: center; display: flex"
        >
          <a href="https://www.lookingglassfactory.com" target="_blank">
            <img
              src="../static/img/lkg-2d-3d-logo.svg"
              style="width: 2rem heigth:"
            />
          </a>
          <button
            class="navbar-toggler mr-0 mt-4 ml-3"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style="float: right"
          >
            <!-- <span class="navbar-toggler-icon bg-gray-400"></span> -->
            <div
              class="overflow-hidden relative w-10 h-10 rounded-full dark:bg-navbarBg"
            >
             <img src="../static/img/icons8_user_1.svg"  style="width: 2rem heigth:"/> 
              <!-- <svg
                class="absolute -left-1 w-12 h-12 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clip-rule="evenodd"
                ></path>
              </svg> -->
            </div>
          </button>
        </div>

        <div class="collapse navbar-collapse mt-4" id="navbarNav">
          <ul class="navbar-nav mr-auto">
            <!-- <li class="nav-item">
              <router-link to="/" class="nav-link text-white">Home</router-link>
            </li> -->
          </ul>
          <ul class="navbar-nav d-none d-md-block">
            <li v-if="!isAuthenticated && !isLoading" class="nav-item">
              <button
                id="qsLoginBtn"
                class="btn btn-primary btn-margin bg-gradient-to-r border-none from-indigo to-barbiePink animate-text"
                style=" border-radius: 10px;"
                @click.prevent="login"
              >
                Login
              </button>
            </li>

            <li class="nav-item dropdown" v-if="isAuthenticated">
              <a
                class="nav-link"
                href="#"
                id="profileDropDown"
                data-toggle="dropdown"
              >
                <div
                  class="overflow-hidden relative w-10 h-10 rounded-full dark:bg-navbarBg"
                >
                <img src="../static/img/icons8_user_1.svg"/> 
                  <!-- <svg
                    class="absolute -left-1 w-12 h-12 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clip-rule="evenodd"
                    ></path>
                  </svg> -->
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-right bg-navbarBg">
                <div class="dropdown-header text-white">{{ user ? user.email : "User" }}</div>
                <a
                  id="qsLogoutBtn"
                  href="#"
                  class="dropdown-item text-white bg-transparent text-gray-300 hover:bg-gray-700 hover:text-white focus:text-white focus:bg-gray-700"
                  @click.prevent="logout"
                >
                  <font-awesome-icon
                    class="mr-3 text-white"
                    icon="power-off"
                  />Log out
                </a>
              </div>
            </li>
          </ul>

          <ul
            class="navbar-nav d-md-none bg-navbarBg"
            v-if="!isAuthenticated && !isLoading"
          >
            <button
              id="qsLoginBtn"
              class="btn btn-primary btn-block text-white bg-gradient-to-r border-none from-indigo to-barbiePink"
              @click="login"
            >
              Log in
            </button>
          </ul>

          <ul
            id="mobileAuthNavBar"
            class="navbar-nav d-md-none d-flex bg-navbarBg"
            v-if="isAuthenticated"
          >
            <li class="nav-item">
              <span class="user-info">
                <h6 class="d-inline-block text-white">{{ user ? user.email : "User" }}</h6>
              </span>
            </li>
            <li>
              <font-awesome-icon icon="power-off" class="mr-3 text-white" />
              <!-- dropdown-item text-white bg-transparent text-gray-300 hover:bg-gray-700 hover:text-white focus:text-white focus:bg-gray-700 -->
              <a
                id="qsLogoutBtn"
                href="#"
                class="text-white hover:bg-gray-700 hover:text-white focus:text-white"
                @click.prevent="logout"
                >Log out</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: "NavBar",
  setup() {
    const auth0 = useAuth0();
    return {
      isAuthenticated: auth0.isAuthenticated,
      isLoading: auth0.isLoading,
      user: auth0.user,
      login() {
        auth0.loginWithRedirect();
      },
      logout() {
        auth0.logout({
          returnTo: window.location.origin,
        });
      },
    };
  },
  data() {
    return {
      loggedIn: this.$store.state.isLoggedIn,
      loggedOut: this.$store.state.isLoggedOut,
    };
  },
};
</script>

<style>
#mobileAuthNavBar {
  min-height: 125px;
  justify-content: space-between;
}
</style>

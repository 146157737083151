<template>
  <client-only>
    <div class="mb-5">
      <Section class="text-center">
        <Container size="small">
          <div class="grid lg:grid-cols-1 gap-4 pt-6 p-3">
            <div
              class="
                p-2
                mt-2
                bg-white
                border-navbarBg
                rounded-xl
                bg-navbarBg
                mt-2
                mb-2
              "
            >
              <div class="max-w-xl mx-auto">
                <Heading class="font-sans text-2xl font-bold mt-4 mb-4">
                  <h1
                    class="
                      text-transparent
                      bg-clip-text bg-gradient-to-r
                      from-nevermindNirvana
                      via-talc via-oddPink via-lightGray
                      to-brightOrage
                      animate-text
                    "
                  >
                    Bring your creations to life with Holograms.
                  </h1>
                </Heading>

                <p class="text-white mt-4 mb-4 mr-4 ml-4 text-left">
                  Instantly add a new dimension to your art, photographs, stills
                  and more. Upload any image and within minutes, our conversion
                  tool generates a detailed depth map that can be used as a
                  hologram for Looking Glass displays.
                </p>
              </div>
              <div
                v-if="!isAuthenticated"
                class="text-center flex items-center justify-center pb-4"
              >
                <div class="inline-block mt-2">
                  <button
                    id="qsLoginBtn"
                    class="
                      btn btn-margin
                      bg-gradient-to-r
                      border-none
                      from-indigo
                      to-barbiePink
                      animate-text
                      text-white
                    "
                    @click.prevent="login"
                    style="border-radius: 10px; min-width: 150px"
                  >
                    Login
                  </button>
                </div>
              </div>
              <div v-if="isAuthenticated" class="inline-block mt-2">
                <Button
                  @click="goto('buy-credits')"
                  class="
                    btn btn-margin
                    mb-4
                    bg-gradient-to-r
                    border-none
                    from-indigo
                    to-barbiePink
                    animate-text
                  "
                  size="large"
                  variant="primary"
                >
                  <a
                    href="#buy-credits"
                    style="pointer-events: none; min-width: 155px"
                    >Buy conversions</a
                  >
                </Button>
              </div>
            </div>
            <div v-if="isAuthenticated" class="text-center">
              <client-only v-if="!isDataLoading">
                <div>
                  <!-- TABS -->
                  <div class="mt-12">
                    <!-- <h2 class="font-bold text-blue-900 text-1xl">TABS</h2> -->
                    <div class="container mx-auto">
                      <div id="tab-button">
                        <ul
                          class="
                            grid
                            gap-4
                            grid-cols-2
                            flex
                            text-sm
                            font-medium
                            text-center text-gray-500
                            dark:text-gray-400
                          "
                        >
                          <Button
                            @click="activeTabOne"
                            class="
                              w-full
                              px-6
                              py-2
                              border-2 border-white
                              font-medium
                              text-xs
                              leading-normal
                              uppercase
                              rounded
                              hover:bg-black hover:bg-opacity-5
                              focus:outline-none
                              focus:ring-0
                              transition
                              duration-150
                              ease-in-out
                            "
                            size="large"
                            variant="secondary"
                            shape="rectangle"
                          >
                            <p class="text-white">New Conversion</p>
                          </Button>
                          <Button
                            @click="activeTabTwo"
                            class="
                              w-full
                              px-6
                              py-2
                              border-2 border-white
                              font-medium
                              text-xs
                              leading-normal
                              uppercase
                              rounded
                              hover:bg-black hover:bg-opacity-5
                              focus:outline-none
                              focus:ring-0
                              transition
                              duration-150
                              ease-in-out
                            "
                            size="large"
                            variant="secondary"
                            shape="rectangle"
                          >
                            <p class="text-white">Conversion History</p>
                          </Button>
                        </ul>
                      </div>
                      <div
                        class="p-4 mt-2 bg-white border rounded-xl bg-navbarBg"
                      >
                        <div v-show="tab === 1">
                          <div>
                            <slot name="New conversion">
                              <div class="mt-4 block">
                                <Section
                                  v-if="error !== null"
                                  class="
                                    p-8
                                    text-center
                                    flex flex-wrap
                                    items-center
                                    justify-center justify-items-center
                                    space-y-4
                                  "
                                >
                                  <Heading
                                    class="
                                      w-full
                                      font-sans font-semibold
                                      text-lg text-white
                                    "
                                  >
                                    Oops! Something went wrong
                                  </Heading>
                                  <p class="w-full text-white">
                                    Reason: {{ error }}
                                  </p>
                                  <p class="w-full pt-5 text-white">
                                    Still failed to upload an image? If the
                                    issue persists, please don’t hesitate to get
                                    in touch with us.
                                    <a
                                      href="https://form.asana.com/?k=NN7iO4u5d6L9Ua3Dt-Hh0w&d=549648722971027"
                                      class="
                                        text-brand-default
                                        underline
                                        text-white
                                      "
                                      target="_blank"
                                      >Report a bug here.</a
                                    >
                                  </p>
                                </Section>
                                <Section v-if="imageUrl && error === null">
                                  <img
                                    ref="convertedImage"
                                    :src="imageUrl"
                                    @error="retryImage"
                                    @load="imageLoad"
                                    alt="Converted Image"
                                    class="sr-only"
                                  />
                                  <div
                                    v-if="converting"
                                    class="
                                      p-8
                                      text-center
                                      flex flex-wrap
                                      items-center
                                      justify-center justify-items-center
                                      space-y-8
                                    "
                                  >
                                    <!-- <Spinner class="text-white" size="48" /> -->
                                    <Heading
                                      class="
                                        w-full
                                        font-sans font-semibold
                                        text-lg
                                      "
                                    >
                                      <p class="text-white">
                                        The conversion process has started
                                      </p>
                                    </Heading>
                                    <p class="w-full text-white">
                                      Thank you for using 2D to 3D converter!
                                      Your images are processing, we will send
                                      you an email when they are ready to
                                      download.
                                    </p>
                                    <Button
                                      id="2d-to-3d-convert-a-new-image-btn"
                                      @click="resetState"
                                      variant="secondary"
                                      component="button"
                                      size="large"
                                      class="w-full bg-black border-white mb-4"
                                    >
                                      <p class="text-white">
                                        Convert a new image
                                      </p>
                                    </Button>
                                  </div>
                                  <div v-if="!converting" class="space-y-4">
                                    <Heading
                                      class="
                                        w-full
                                        font-sans font-semibold
                                        text-lg text-white
                                      "
                                    >
                                      Your converted image will be ready
                                      shortly!
                                    </Heading>
                                    <p class="w-full text-white">
                                      Thank you for using 2D to 3D converter!
                                      Your images are processing, we will send
                                      you an email when they are ready to
                                      download.
                                    </p>
                                    <Button
                                      id="2d-to-3d-convert-a-new-image-btn"
                                      @click="resetState"
                                      variant="secondary"
                                      component="button"
                                      size="large"
                                      class="w-full bg-black border-white mb-4"
                                    >
                                      <p class="text-white">
                                        Convert a new image
                                      </p>
                                    </Button>
                                  </div>
                                </Section>
                                <Section
                                  v-if="processing && error === null"
                                  class="
                                    p-8
                                    text-center
                                    flex flex-wrap
                                    items-center
                                    justify-center justify-items-center
                                    space-y-8
                                  "
                                >
                                  <Spinner class="text-white" size="48" />
                                  <Heading
                                    class="
                                      w-full
                                      font-sans font-semibold
                                      text-lg text-white
                                    "
                                  >
                                    The conversion process has started
                                  </Heading>
                                  <p class="w-full text-white">
                                    Thank you for using 2D to 3D converter! Your
                                    images are processing, we will send you an
                                    email when they are ready to download.
                                  </p>
                                </Section>
                                <div
                                  v-if="
                                    !processing && !imageUrl && error === null
                                  "
                                  class="text-left text-white"
                                >
                                  <FilePond
                                    id="2d-to-3d-dnd"
                                    ref="pond"
                                    :allow-multiple="true"
                                    :max-files="8"
                                    :max-file-size="4500000"
                                    :allow-revert="false"
                                    :instant-upload="false"
                                    :files="myFiles"
                                    v-on:updatefiles="handleUpdatedFiles"
                                    v-on:init="handleFilePondInit"
                                    name="test"
                                    label-idle="<strong>Drop .png,  jpeg files here or click to upload...</strong><br> (Max 8 files upto 4.5 MB each)"
                                    accepted-file-types="image/jpeg, image/png"
                                  />
                                  <!-- Drop an image here or click to upload. <strong>You can drag in only one image</strong>.<br> Accepted formats: JPEG, PNG." -->
                                  <Button
                                    id="2d-to-3d-convert-btn"
                                    v-if="
                                      readyToUpload &&
                                      acceptedTerms &&
                                      activeCode
                                    "
                                    @click="handleUpload"
                                    variant="secondary"
                                    class="w-full mt-4 bg-black border-white"
                                    size="large"
                                  >
                                    <p class="text-white">Convert</p>
                                  </Button>
                                </div>
                              </div>
                            </slot>
                          </div>
                        </div>
                        <div v-show="tab === 2">
                          <div>
                            <slot name="Conversion history">
                              <Section class="mt-4 block">
                                <div class="text-left">
                                  <Heading
                                    class="font-sans text-lg font-semibold"
                                  >
                                    <p
                                      class="text-white"
                                      style="
                                        justify-content: center;
                                        align-items: center;
                                        display: flex;
                                      "
                                    >
                                      Conversion history
                                    </p>
                                  </Heading>
                                  <div class="text-white mt-4 ml-6">
                                    <p
                                      style="
                                        justify-content: center;
                                        align-items: center;
                                        display: flex;
                                      "
                                    >
                                      Your converted images will be stored in
                                      our servers for a maximum of 24 hours.
                                      After that period, images will be deleted
                                      and links will no longer work.
                                    </p>
                                  </div>
                                  <div
                                    class="
                                      border border-gray-300
                                      rounded-md
                                      overflow-hidden
                                      my-8
                                    "
                                  >
                                    <table
                                      class="conversions rounded-md w-full"
                                      aria-label="Conversion history"
                                    >
                                      <thead>
                                        <tr
                                          class="
                                            divide-x divide-gray-300
                                            bg-gray-100
                                          "
                                        >
                                          <!-- <th class="p-2">Date</th> -->
                                          <!-- <th class="p-2">Code</th> -->
                                          <th class="p-2"></th>
                                          <!-- <th class="p-2">Image</th> -->
                                        </tr>
                                      </thead>
                                      <tbody
                                        v-if="
                                          userData &&
                                          userData.conversionData.conversions &&
                                          userData.conversionData.conversions
                                            .length > 0
                                        "
                                      >
                                        <tr
                                          v-for="(
                                            conversion, conversionIndex
                                          ) in paginatedConversions"
                                          :key="conversionIndex"
                                          class="divide-x divide-gray-300"
                                        >
                                          <!-- <td class="p-2">
                                            {{
                                              getFormattedDate(conversion.date)
                                            }}
                                          </td> -->
                                          <!-- <td class="p-2">
                                            {{ conversion.code }}
                                          </td> -->
                                          <td class="p-6">
                                            <div style="display: inline">
                                              <span
                                                style="
                                                  float: left;
                                                  font-size: 10px;
                                                "
                                                class="text-white"
                                              >
                                                {{
                                                  getFormattedDate(
                                                    conversion.date
                                                  )
                                                }}
                                              </span>
                                              <a
                                                v-if="
                                                  getDownloadableState(
                                                    conversion.date
                                                  ) === 1 ||
                                                  (!converting &&
                                                    imageUrl &&
                                                    conversion.url &&
                                                    imageUrl.toString() ===
                                                      conversion.url.toString())
                                                "
                                                :href="conversion.url"
                                                class="
                                                  text-brand-default
                                                  justify-center
                                                  inline-flex
                                                  underline
                                                  text-white
                                                  mb-1
                                                "
                                                target="_blank"
                                                variant="tertiary"
                                                size="large"
                                                style="float: right"
                                              >
                                                Download
                                              </a>
                                              <span
                                                v-else-if="
                                                  getDownloadableState(
                                                    conversion.date
                                                  ) === 0
                                                "
                                                style="
                                                  float: right;
                                                  display: flex;
                                                  flex;justify-content: center;
                                                  align-items: center;
                                                "
                                                ><Spinner
                                                  class="text-white mr-2"
                                                  size="12"
                                                />
                                                <p class="text-white text-sm">
                                                  processing...
                                                </p>
                                              </span>
                                              <span
                                                v-else
                                                style="float: right; text-white"
                                                >Expired</span
                                              >
                                              <!-- <span style="align-items: center;justify-content: center;display: flex;" >{{ conversion.code }}</span > -->
                                            </div>
                                            <div
                                              v-if="
                                                getDownloadableState(
                                                  conversion.date
                                                ) === 1 ||
                                                (!converting &&
                                                  imageUrl &&
                                                  conversion.url &&
                                                  imageUrl.toString() ===
                                                    conversion.url.toString())
                                              "
                                              :href="conversion.url"
                                              class="
                                                text-brand-default
                                                underline
                                              "
                                            >
                                              <img
                                                ref="convertedImage"
                                                :src="conversion.url"
                                                alt="Converted Image"
                                                class="w-small rounded-md mb-5"
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Section>
                            </slot>
                          </div>
                        </div>
                      </div>
                      <Section>
                        <div
                          class="
                            grid
                            gap-20
                            grid-cols-2
                            flex
                            text-sm
                            font-medium
                            text-center text-gray-500
                            dark:border-gray-700
                            dark:text-gray-400
                          "
                        >
                          <Button
                            variant="tertiary"
                            size="large"
                            type="button"
                            class="
                              mt-2
                              inline-flex
                              mb-2
                              md:mb-0
                              text-white
                              bg-blue-700
                              hover:bg-blue-800
                              focus:ring-4
                              focus:outline-none
                              focus:ring-blue-300
                              font-medium
                              rounded-lg
                              text-sm
                              px-5
                              py-2.5
                              text-center
                              dark:bg-blue-600
                              dark:hover:bg-blue-700
                              dark:focus:ring-blue-800
                            "
                          >
                            <p class="text-white">
                              Credits remaining: {{ userData.remainingCredits }}
                            </p>
                          </Button>
                          <div>
                            <Button
                              @click="openRedeemModal"
                              variant="secondary"
                              class="inline-flex mt-2"
                              size="large"
                            >
                              <p class="text-white">Redeem code</p>
                            </Button>
                          </div>
                        </div>
                      </Section>
                      <div v-if="redeemModalOpen" class="grid mt-2">
                        <Section>
                          <div
                            v-if="redeemStatus === 'success'"
                            role="alert"
                            class="
                              bg-utility-positive
                              text-white
                              rounded-md
                              p-4
                              text-center
                              mb-4
                            "
                          >
                            <p class="text-white">{{ redeemMessage }}</p>
                          </div>
                          <div
                            v-if="redeemStatus === 'error'"
                            role="alert"
                            class="
                              bg-utility-critical
                              text-white
                              rounded-md
                              p-4
                              text-center
                              mb-4
                            "
                          >
                            <p class="text-white">{{ redeemMessage }}</p>
                          </div>
                          <Heading
                            class="
                              font-sans
                              text-lg
                              font-semibold
                              mb-4
                              text-white
                            "
                          >
                            Redeem new code
                          </Heading>
                          <div>
                            <input
                              :required="true"
                              :small="true"
                              v-model="newRedemptionCode"
                              type="text"
                              label="Redemption code"
                              class="w-full rounded-3xl py-2 px-4 mb-4"
                            />
                            <div class="flex items-center justify-center gap-6">
                              <Button
                                @click="closeRedeemModal"
                                variant="secondary"
                                class="w-small"
                                size="large"
                                color="utility-critical"
                              >
                                Close
                              </Button>
                              <Button
                                @click="redeemCode"
                                :disabled="
                                  newRedemptionCode === '' ||
                                  newRedemptionCode.length < 20
                                "
                                size="large"
                                class="w-small"
                              >
                                <span v-if="redeemStatus !== 'loading'"
                                  >Redeem</span
                                >
                                <Spinner
                                  v-if="redeemStatus === 'loading'"
                                  size="24"
                                />
                              </Button>
                            </div>
                          </div>
                        </Section>
                      </div>
                    </div>
                  </div>
                  <!-- TABS END -->
                </div>
              </client-only>
              <div
                v-else
                class="flex items-center justify-center text-center py-16"
              >
                <Spinner :size="48" />
              </div>
            </div>
          </div>
        </Container>
        <div class="mt-4">
          <Container size="small">
            <div class="space-y-4 md:space-y-16">
              <div class="mt-12 space-y-4 grid lg:grid-cols-12 gap-12 mb-4">
                <img
                  class="rounded-md w-1/2 col-span-6"
                  src="../static/img/cosmiccat.png"
                  alt="A sample 2D image"
                  style="width: 100%"
                />
                <div class="col-span-6 text-left">
                  <Heading
                    class="
                      font-sans
                      text-2xl
                      font-bold
                      text-transparent
                      bg-clip-text bg-gradient-to-r
                      from-nevermindNirvana
                      via-talc via-oddPink via-lightGray
                      to-brightOrage
                      animate-text
                      mb-4
                    "
                  >
                    Generate.
                  </Heading>
                  <p class="text-white">
                    Whether you’re using tools like Dall-E, Stable Diffusion,
                    Midjourney, Photoshop, or straight from your camera,
                    generate your dream images into reality.
                  </p>
                </div>
              </div>
              <div class="space-y-4 grid lg:grid-cols-12 gap-12 my-12">
                <div class="col-span-6 my-auto text-left">
                  <img
                    class="
                      rounded-md
                      w-full
                      col-span-6
                      inline-block
                      md:hidden
                      mb-16
                    "
                    src="../static/img/cosmic_cat_depth.png"
                    alt="A sample 2D image along with its depth map"
                    style="width: 100%"
                  />
                  <Heading
                    class="
                      font-sans
                      text-2xl
                      font-bold
                      text-transparent
                      bg-clip-text bg-gradient-to-r
                      from-nevermindNirvana
                      via-talc via-oddPink via-lightGray
                      to-brightOrage
                      animate-text
                      mb-4
                    "
                  >
                    Convert.
                  </Heading>

                  <p class="text-white">
                    The world is your oyster, add a layer of depth to your
                    imagination. Bring your creations into the third dimension
                    with our image to hologram conversion tool.
                  </p>
                </div>
                <img
                  class="rounded-md w-full col-span-6 hidden md:inline-block"
                  src="../static/img/cosmic_cat_depth.png"
                  alt="A sample 2D image along with its depth map"
                  style="width: 100%"
                />
              </div>
              <div class="space-y-4 grid lg:grid-cols-12 gap-12 mb-4">
                <img
                  class="rounded-md w-full col-span-6"
                  src="../static/img/cosmic-cat.webp"
                  alt="A Looking Glass displaying a hologram"
                  style="width: 100%"
                />
                <div class="col-span-6 text-left">
                  <Heading
                    class="
                      font-sans
                      text-2xl
                      font-bold
                      text-transparent
                      bg-clip-text bg-gradient-to-r
                      from-nevermindNirvana
                      via-talc via-oddPink via-lightGray
                      to-brightOrage
                      animate-text
                      mb-4
                    "
                  >
                    Display.
                  </Heading>

                  <p class="text-white">
                    The Looking Glass is a new three-dimensional canvas for your
                    explorations. Bring what you have and we’ll give you the
                    tools to adjust, tweak, perfect, and showcase your work in
                    3D, as holograms.
                  </p>
                </div>
              </div>
              <br />

              <Section>
                <div
                  class="
                    grid
                    lg:grid-cols-12
                    gap-12
                    mb-4
                    bg-neutral600
                    rounded-lg
                    sm-350
                    xl-auto
                    xl:items-start-fixed
                  "
                >
                  <div
                    class="
                      text-white
                      rounded-lg
                      flex flex-col
                      place-items-left
                      col-span-6
                      p-4
                      sm-350
                      xl-auto
                    "
                  >
                    <p class="text-xs md:text-sm text-left font-bold mb-2">
                      The Portrait Holiday Bundle
                    </p>
                    <h1
                      class="
                        font-bold
                        text-transparent
                        bg-clip-text bg-gradient-to-r
                        from-nevermindNirvana
                        via-talc via-oddPink via-lightGray
                        to-brightOrage
                        animate-text
                        text-xl
                        md:text-2xl
                        text-left
                        mb-2
                      "
                    >
                      SAVE $65!
                    </h1>
                    <div class="text-xs md:text-sm text-left">
                      Buy a Portrait today and receive a free carrying case, 100
                      2D-to-3D conversion credits and free shipping (US, Japan
                      only).
                    </div>
                    <button
                      class="
                        cursor-pointer
                        ml-0
                        mt-6
                        rounded-lg
                        btn btn-margin
                        bg-gradient-to-r
                        border-none
                        from-indigo
                        to-barbiePink
                        animate-text
                        text-white
                        shrink
                        w-56
                        h-12
                        hover:brightness-95
                      "
                      onclick="location.href='https://lookingglassfactory.com/product/looking-glass-portrait-holoday?utm_source=2d-to-3d&utm_medium=Looking+glass&utm_campaign=holoday_bundle&utm_id=portrait+holoday+bundle'"
                    >
                      Order Now
                    </button>
                  </div>
                  <div class="col-span-6">
                    <img
                      class="
                        lg:h-80
                        md:
                        object-fill
                        lg:object-cover
                        md:h-200
                        xl:h-80
                        xl:object-cover
                      "
                      src="../static/img/Portrait-image.jpg"
                      alt="Looking Glass Portrait"
                    />
                  </div>
                </div>
                <br />
                <div
                  id="buy-credits"
                  ref="buy-credits"
                  class="mt-10 mx-auto u-text-center"
                >
                  <Heading
                    class="
                      font-sans
                      text-2xl
                      mb-2
                      font-bold
                      text-transparent
                      bg-clip-text bg-gradient-to-r
                      from-nevermindNirvana
                      via-talc via-oddPink via-lightGray
                      to-brightOrage
                      animate-text
                    "
                  >
                    Start Converting Today
                  </Heading>
                  <br />
                  <div class="inline-flex flex-row gap-6">
                    <div
                      class="
                        max-w-sm
                        rounded
                        overflow-hidden
                        shadow-lg
                        border-navbarBg
                        bg-navbarBg
                      "
                    >
                      <div
                        class="mb-4"
                        id="product-component-1660252932684"
                      ></div>
                    </div>
                    <div
                      class="
                        max-w-sm
                        rounded
                        overflow-hidden
                        shadow-lg
                        border-navbarBg
                        bg-navbarBg
                      "
                    >
                      <div
                        class="mb-4"
                        id="product-component-1660252878127"
                      ></div>
                    </div>
                  </div>
                </div>
              </Section>
            </div>
          </Container>
        </div>
        <div class="mt-14">
          <Container size="small">
            <Section class="py-8">
              <Heading
                class="
                  font-sans font-bold
                  text-2xl
                  mb-8
                  font-bold
                  text-transparent
                  bg-clip-text bg-gradient-to-r
                  from-nevermindNirvana
                  via-talc via-oddPink via-lightGray
                  to-brightOrage
                  animate-text
                "
              >
                FAQ
              </Heading>
              <div class="space-y-4 text-left text-white">
                <Accordion
                  label="What sorcery is this? How does this technology work?"
                  large
                >
                  <div class="px-4 pb-4 prose-lg max-w-3xl text-white">
                    <p>
                      The cloud-based technology is provided in partnership with
                      Owl3D, an AI platform that specializes in converting 2D
                      content into 3D content. A high-quality depth map is
                      deduced from the uploaded 2D image, which is then used to
                      generate the multiple perspectives required to display a
                      three-dimensional hologram in the Looking Glass.
                    </p>
                  </div>
                </Accordion>
                <Accordion
                  label="What type of images make the best holograms?"
                  large
                >
                  <div class="px-4 pb-4 prose-lg max-w-3xl">
                    <!-- <p><img src="/img/2dto3d-depth.jpg" alt="A less depth vs a more depth image" clas="u-12/12 u-radius-medium"></p> -->
                    <p>
                      When selecting images to convert into holograms, a clear
                      picture with 3D geometric cues is usually enough to
                      generate a convincing depth map for holographic display.
                    </p>
                    <p>
                      We suggest that you avoid converting visually flat images,
                      like a photo of the sky or simple drawings, as the output
                      is more likely to fail at capturing plausible depth.
                    </p>
                    <p>
                      Images must be smaller than 7.3mb, and up to 5K
                      resolution.
                    </p>
                  </div>
                </Accordion>
                <Accordion
                  label="Who owns the copyright to the depth map?"
                  large
                >
                  <div class="px-4 pb-4 prose-lg max-w-3xl">
                    <p>
                      The resulting depth map belongs to you. Looking Glass
                      Factory only temporarily hosts the image for you for 24
                      hours. The same applies to photos you upload with the
                      2D-to-3D tool: you own them, and not Looking Glass
                      Factory.
                    </p>
                  </div>
                </Accordion>
                <Accordion
                  label="How can I find past 2D images I've converted into 3D?"
                  large
                >
                  <div class="px-4 pb-4 prose-lg max-w-3xl">
                    <p>
                      When you convert a 2D image into a depth map, you can find
                      the result in the "Conversion History" tab above the
                      2D-to-3D tool's interface. We only host your images for 24
                      hours after conversion, so please remember to download
                      your depth map once it has been generated!
                    </p>
                  </div>
                </Accordion>
              </div>
            </Section>
          </Container>
        </div>
        <Portal v-if="redeemModalOpen" to="modal">
          <!-- <Modal @close="closeRedeemModal" label="Redeem code">
            <Section>
              <div
                v-if="redeemStatus === 'success'"
                role="alert"
                class="bg-utility-positive text-white rounded-md p-4 text-center mb-4"
              >
                <p>{{ redeemMessage }}</p>
              </div>
              <div
                v-if="redeemStatus === 'error'"
                role="alert"
                class="bg-utility-critical text-white rounded-md p-4 text-center mb-4"
              >
                <p>{{ redeemMessage }}</p>
              </div>
              <Heading class="font-sans text-lg font-semibold mb-4">
                Redeem new code
              </Heading>
              <div>
                <input
                  :required="true"
                  :small="true"
                  v-model="newRedemptionCode"
                  type="text"
                  label="Redemption code"
                  class="w-full rounded-3xl py-2 px-4 mb-4"
                />
                <Button
                  @click="redeemCode"
                  :disabled="
                    newRedemptionCode === '' || newRedemptionCode.length < 20
                  "
                  size="large"
                  class="w-full"
                >
                  <span v-if="redeemStatus !== 'loading'">Redeem</span>
                  <Spinner v-if="redeemStatus === 'loading'" size="24" />
                </Button>
              </div>
            </Section>
          </Modal> -->
        </Portal>
      </Section>
    </div>
  </client-only>
</template>

<script>
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-vue";
// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Import FilePond plugins
// Please note that you need to install these plugins separately
// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateSize
);

// const Modal = () => import("../components/Modal.vue");
// import Pagination from "../../node_modules/holo-design-system-vue/src/components/Pagination.vue"; //node_modules/holo-design-system-vue/src/**/*.vue/Pagination.vue";
import Spinner from "holo-design-system-vue/src/components/Spinner.vue";
import Section from "holo-design-system-vue/src/components/Section.vue";
import Heading from "holo-design-system-vue/src/components/Heading.vue";
import Container from "holo-design-system-vue/src/components/Container.vue";
import Button from "holo-design-system-vue/src/components/Button.vue";
import Accordion from "holo-design-system-vue/src/components/Accordion.vue";

let imageTimeout;

export default {
  setup() {
    const auth0 = useAuth0();
    const { user } = useAuth0();
    return {
      isAuthenticated: auth0.isAuthenticated,
      isLoading: auth0.isLoading,
      // user: auth0.user,
      user,
      login() {
        auth0.loginWithRedirect();
      },
      logout() {
        auth0.logout({
          returnTo: window.location.origin,
        });
      },
    };
  },
  components: {
    Section,
    Heading,
    Container,
    Button,
    // Tabs,
    // Modal,
    FilePond,
    // Pagination,
    Spinner,
    Accordion,
    // Spinner,
  },
  asyncData(context) {
    return { isDev: context.isDev };
  },
  data() {
    return {
      profile: null,
      tab: 1,
      openTab: 1,
      tabList: ["Tab 1", "Tab 2", "Tab 3", "Tab 4"],
      accessToken: "",
      myFiles: [],
      convertedUrlsLocalStore: [],
      base64files: [],
      readyToUpload: false,
      acceptedTerms: true,
      imageUrl: null,
      processing: false,
      converting: false,
      currentTab: "new",
      error: null,
      activeCode: [],
      isDataLoading: true,
      newRedemptionCode: "",
      redeemStatus: "",
      sendEmail: false,
      counter: 0,
      redeemMessage: "",
      endpoint: process.env.VUE_APP_API_URL,
      redeemModalOpen: false,
      userData: {},
      currentHistoryPage: 1,
    };
  },
  created() {},
  computed: {
    allCodes() {
      const all = [];
      if (
        !this.isDataLoading &&
        this.userData &&
        this.userData.conversionData.codes &&
        this.userData.conversionData.codes.length > 0
      ) {
        this.userData.conversionData.codes.forEach((code) => {
          if (!code.conversions) {
            code.conversions = [];
          }
          if (code.credits) {
            all.push(code);
          }
        });
      }
      return all;
    },
    availableCodes() {
      const available = [];
      if (
        !this.isDataLoading &&
        this.userData &&
        this.userData.conversionData.codes &&
        this.userData.conversionData.codes.length > 0
      ) {
        this.userData.conversionData.codes.forEach((code) => {
          if (!code.conversions) {
            code.conversions = [];
          }
          if (code.credits && code.conversions.length < code.credits) {
            for (let i = 0; i < code.credits - code.conversions.length; i++) {
              available.push(code);
            }
          }
        });
      }
      return available;
    },
    availableCredits() {
      let count = 0;
      if (
        !this.isDataLoading &&
        this.userData &&
        this.userData.conversionData.codes &&
        this.userData.conversionData.codes.length > 0
      ) {
        this.availableCodes.forEach((code) => {
          count = count + (code.credits - code.conversions.length);
          this.activeCode.push(code);
        });
      }

      return this.activeCode.length;
    },
    paginatedConversions() {
      const perPage = this.userData.conversionData.conversions.length;
      const conversions = Array.from(this.userData.conversionData.conversions)
        .reverse()
        .slice(
          this.currentHistoryPage * perPage - perPage,
          this.currentHistoryPage * perPage
        );
      return conversions;
    },
  },
  watch: {
    // availableCodes(value) {
    //   if (value.length > 0) {
    //     this.activeCode = value
    //     console.log(this.activeCode) // eslint-disable-line
    //   } else {
    //     this.activeCode = null
    //   }
    // }
  },
  mounted() {
    this.checkLoggedIn();
    // if (!this.isDev) {
    //   this.checkLoggedIn();
    // } else {
    //   this.profile = { username: "mariano@mariano.io" };
    //   this.getUserData(this.profile)
    //     .then((r) => {
    //       this.isDataLoading = false;
    //     })
    //     .catch((e) => {
    //       this.error = e.error;
    //     });
    // }
  },
  methods: {
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },
    activeTabOne() {
      this.tab = 1;
    },
    activeTabTwo() {
      this.tab = 2;
    },
    activeTabThree() {
      this.tab = 3;
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    getFormattedDate(date) {
      const dt = new Date(date);
      const string = `${(dt.getMonth() + 1).toString().padStart(2, "0")}/${dt
        .getDate()
        .toString()
        .padStart(2, "0")}/${dt.getFullYear().toString().padStart(4, "0")} ${dt
        .getHours()
        .toString()
        .padStart(2, "0")}:${dt.getMinutes().toString().padStart(2, "0")}`;
      return string;
    },
    getDownloadableState(date) {
      const dt = new Date(date);
      const now = new Date();
      const diff = (now.getTime() - dt.getTime()) / 1000;
      const hours = Math.abs(Math.round(diff / (60 * 60)));
      // -1 f 24 hrs have elapsed; 0 if 3 mins have not elapsed; 1 otherwise
      return hours >= 24 ? -1 : diff <= 60 * 3 ? 0 : 1;
    },
    handleFilePondInit() {
      // FilePond instance methods are available on `this.$refs.pond`
    },
    async handleUpdatedFiles(files) {
      // let count = 9;
      this.myFiles = files.map((files) => files.file);
      this.readyToUpload = true;
    },
    retryImage() {
      const vm = this;
      if (imageTimeout) {
        return;
      }
      imageTimeout = setTimeout(function () {
        vm.$refs.convertedImage &&
          vm.$refs.convertedImage.setAttribute("src", "");
        vm.$refs.convertedImage &&
          vm.$refs.convertedImage.setAttribute("src", vm.imageUrl);
        imageTimeout = undefined;
      }, 5000);
    },
    imageLoad() {
      this.converting = false;
    },
    handleUpload() {
      let vue = this;
      vue.processing = true;
      vue.counter = 0;
      let convertedImages = [];
      const imagefiles = this.myFiles;
      let fetchAccessToken = vue.accessToken;
      async function upload() {
        console.log("IMAGE FILES ", imagefiles);
        imagefiles.forEach(async function (file, i) {
          const formData = new FormData();

          if (!vue.userData.conversionData.validCodes.length) {
            vue.error = "Insufficient credits for conversion";
            console.log("Insufficient credits for conversion");
            return;
          }
          console.log("USING CODE ", vue.userData.conversionData.validCodes[0]);
          formData.append("image", file);
          formData.append("uid", vue.profile.email);
          formData.append("code", vue.userData.conversionData.validCodes[0]);
          formData.append("type", file.type);
          if (!vue.$store.state.convertedUrls.includes(file.name)) {
            vue.$store.commit("appendToConvertedUrls", file.name);
            axios
              .post(`${vue.endpoint}/convert`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${fetchAccessToken}`,
                },
              })
              .then(async ({ data: { userUrl } }) => {
                vue.counter += 1;
                console.log("Posted"); // eslint-disable-line
                vue.imageUrl = userUrl;
                convertedImages.push(userUrl, file.name);

                let latestUserData = await vue.getUserData(vue.profile);

                if (vue.counter === imagefiles.length) {
                  // Call email API
                  let creditsLeft = latestUserData.data.remainingCredits
                    ? latestUserData.data.remainingCredits
                    : vue.userData.remainingCredits;

                  vue.sendEmailNotifications(
                    vue.profile.email,
                    imagefiles.length,
                    creditsLeft
                  );
                  vue.counter = 0;
                }
              })
              .catch((e) => {
                console.log("Error /convert api", e); // eslint-disable-line
                vue.error = e.error
                  ? e.error
                  : "Something went wrong while uploading your image. Images must be smaller than 7.3mb, and up to 5K resolution. Please reload this page and try again.";
              });
          }

          return i;
        });
      }

      if (vue.$store.state.convertedUrls.length - 1 <= this.myFiles.length) {
        upload();
        vue.processing = false;
        vue.converting = false;
      }
      this.converting = true;
      return;
    },
    async sendEmailNotifications(userEmail, numberOfImages, remainingCredits) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        user_email: userEmail,
        image_count: numberOfImages,
        remaining_credits: remainingCredits,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(
        `${process.env.VUE_APP_API_URL}/email-notifications/webhook`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          console.log(result);
          return 1;
        })
        .catch((error) => {
          console.log("error", error);
          return 0;
        });
    },
    async checkLoggedIn() {
      const auth0 = useAuth0();

      if (auth0.isAuthenticated) {
        // store token in the state
        // accessToken
        try {
          this.accessToken = await auth0.getAccessTokenSilently();
        } catch (error) {
          console.log("error: " + error);
        }
        // this.$store.commit("updateAccessToken", this.accessToken);
      }

      if (this.isAuthenticated && this.accessToken) {
        // this.$store.commit("updateUserDetails", this.user);
        let userDetails = {
          nickname: this.user.nickname,
          name: this.user.name,
          picture: this.user.picture,
          updated_at: this.user.updated_at,
          email: this.user.email,
          email_verified: this.user.email_verified,
          id: this.user.sub,
        };

        this.profile = userDetails;
        this.getUserData(userDetails)
          // eslint-disable-next-line no-unused-vars
          .then((r) => {
            this.isDataLoading = false;
          })
          .catch((e) => {
            console.log("Error getUserData ", e);
            this.error = e.error
              ? e.error
              : "Something went wrong while initializing this page. If this issue persists, don't hesitate to get in touch with us.";
          });
      }
    },
    getUserData(profile) {
      const accessToken = this.accessToken;
      console.log("PRofile ", profile.email);

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.endpoint}/user`,
            {
              email: profile.email,
            },
            {
              // withCredentials: true,
              // crossDomain: true,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
              },
            }
          )
          .then((userData) => {
            console.log("API CALL SUCCESS ");

            this.userData = userData.data;

            resolve(userData);
          })
          .catch((e) => {
            console.log("Error /user api", e);
            this.error = e.error
              ? e.error
              : "Something went wrong while initializing this page. If this issue persists, don't hesitate to get in touch with us.";
            reject(e);
          });
      });
    },
    redeemCode() {
      if (this.newRedemptionCode === "" || this.newRedemptionCode.length < 20) {
        return;
      }
      this.redeemStatus = "loading";
      axios
        .post(
          `${this.endpoint}/code`,
          {
            uid: this.profile.email,
            id: this.newRedemptionCode,
          },
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        )
        .then((r) => {
          if (r.status === 200) {
            this.redeemStatus = "success";
            this.redeemMessage = "Your code was redeemed successfully!";
            this.newRedemptionCode = "";
            // this.getUserData(this.profile)
            location.reload();
          } else {
            this.redeemStatus = "error";
            console.log("ERROR ", r.data.error, r.data);
            this.redeemMessage = r.error
              ? r.error
              : "Something went wrong while redeeming your code. If this issue persists, don't hesitate to get in touch with us.";

            setTimeout(() => {
              this.redeemMessage = "";
              this.redeemStatus = "";
            }, 2000);
          }
        })
        .catch((e) => {
          /* eslint-disable */
          console.log("Error /code api", e);
          /* eslint-enable */
          this.redeemStatus = "error";
          this.redeemMessage = e.error
            ? e.error
            : "Something went wrong while redeeming your code. If this issue persists, don't hesitate to get in touch with us.";
          setTimeout(() => {
            this.redeemMessage = "";
            this.redeemStatus = "";
          }, 2000);
        });
    },
    openRedeemModal() {
      this.redeemStatus = "";
      this.newRedemptionCode = "";
      this.redeemModalOpen = true;
    },
    closeRedeemModal() {
      this.redeemModalOpen = false;
      this.redeemStatus = "";
      this.newRedemptionCode = "";
    },
    resetState() {
      this.myFiles = [];
      this.base64files = [];
      this.readyToUpload = false;
      this.acceptedTerms = true;
      this.imageUrl = null;
      this.processing = false;
      this.converting = false;
      this.error = null;
      this.$store.commit("clearConvertedUrlsArr", 0);
    },
  },
};
/* eslint-enable */

// Shopify 100 Credits

(function () {
  var scriptURL =
    "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
  if (window.ShopifyBuy) {
    if (window.ShopifyBuy.UI) {
      ShopifyBuyInit();
    } else {
      loadScript();
    }
  } else {
    loadScript();
  }
  function loadScript() {
    var script = document.createElement("script");
    script.async = true;
    script.src = scriptURL;
    (
      document.getElementsByTagName("head")[0] ||
      document.getElementsByTagName("body")[0]
    ).appendChild(script);
    script.onload = ShopifyBuyInit;
  }
  function ShopifyBuyInit() {
    // eslint-disable-next-line no-undef
    var client = ShopifyBuy.buildClient({
      domain: "looking-glass-factory-2.myshopify.com",
      storefrontAccessToken: "5a074389197c0abf2be0e218703fcc18",
    });
    // eslint-disable-next-line no-undef
    ShopifyBuy.UI.onReady(client).then(function (ui) {
      ui.createComponent("product", {
        id: "6559203197017",
        node: document.getElementById("product-component-1660252878127"),
        moneyFormat: "%24%7B%7Bamount%7D%7D",
        options: {
          product: {
            styles: {
              product: {
                "@media (min-width: 601px)": {
                  "max-width": "calc(25% - 20px)",
                  "margin-left": "20px",
                  "margin-bottom": "50px",
                },
              },
              title: {
                color: "#ffffff",
              },
              button: {
                ":hover": {
                  "background-color": "#5353e6",
                },
                "background-color": "#5c5cff",
                ":focus": {
                  "background-color": "#5353e6",
                },
              },
              price: {
                color: "#fdfcfc",
              },
              compareAt: {
                color: "#fdfcfc",
              },
              unitPrice: {
                color: "#fdfcfc",
              },
            },
            text: {
              button: "Add to cart",
            },
          },
          productSet: {
            styles: {
              products: {
                "@media (min-width: 601px)": {
                  "margin-left": "-20px",
                },
              },
            },
          },
          modalProduct: {
            contents: {
              img: false,
              imgWithCarousel: true,
              button: false,
              buttonWithQuantity: true,
            },
            styles: {
              product: {
                "@media (min-width: 601px)": {
                  "max-width": "100%",
                  "margin-left": "0px",
                  "margin-bottom": "0px",
                },
              },
              button: {
                ":hover": {
                  "background-color": "#5353e6",
                },
                "background-color": "#5c5cff",
                ":focus": {
                  "background-color": "#5353e6",
                },
              },
              title: {
                "font-family": "Helvetica Neue, sans-serif",
                "font-weight": "bold",
                "font-size": "26px",
                color: "#4c4c4c",
              },
              price: {
                "font-family": "Helvetica Neue, sans-serif",
                "font-weight": "normal",
                "font-size": "18px",
                color: "#4c4c4c",
              },
              compareAt: {
                "font-family": "Helvetica Neue, sans-serif",
                "font-weight": "normal",
                "font-size": "15.299999999999999px",
                color: "#4c4c4c",
              },
              unitPrice: {
                "font-family": "Helvetica Neue, sans-serif",
                "font-weight": "normal",
                "font-size": "15.299999999999999px",
                color: "#4c4c4c",
              },
            },
            text: {
              button: "Add to cart",
            },
          },
          option: {},
          cart: {
            styles: {
              button: {
                ":hover": {
                  "background-color": "#5353e6",
                },
                "background-color": "#5c5cff",
                ":focus": {
                  "background-color": "#5353e6",
                },
              },
              title: {
                color: "#ffffff",
              },
              header: {
                color: "#ffffff",
              },
              lineItems: {
                color: "#ffffff",
              },
              subtotalText: {
                color: "#ffffff",
              },
              subtotal: {
                color: "#ffffff",
              },
              notice: {
                color: "#ffffff",
              },
              currency: {
                color: "#ffffff",
              },
              close: {
                color: "#ffffff",
                ":hover": {
                  color: "#ffffff",
                },
              },
              empty: {
                color: "#ffffff",
              },
              noteDescription: {
                color: "#ffffff",
              },
              discountText: {
                color: "#ffffff",
              },
              discountIcon: {
                fill: "#ffffff",
              },
              discountAmount: {
                color: "#ffffff",
              },
              cart: {
                "background-color": "#212121",
              },
              footer: {
                "background-color": "#212121",
              },
            },
            text: {
              total: "Subtotal",
              button: "Checkout",
            },
            popup: false,
          },
          toggle: {
            styles: {
              toggle: {
                "background-color": "#5c5cff",
                ":hover": {
                  "background-color": "#5353e6",
                },
                ":focus": {
                  "background-color": "#5353e6",
                },
              },
            },
          },
          lineItem: {
            styles: {
              variantTitle: {
                color: "#ffffff",
              },
              title: {
                color: "#ffffff",
              },
              price: {
                color: "#ffffff",
              },
              fullPrice: {
                color: "#ffffff",
              },
              discount: {
                color: "#ffffff",
              },
              discountIcon: {
                fill: "#ffffff",
              },
              quantity: {
                color: "#ffffff",
              },
              quantityIncrement: {
                color: "#ffffff",
                "border-color": "#ffffff",
              },
              quantityDecrement: {
                color: "#ffffff",
                "border-color": "#ffffff",
              },
              quantityInput: {
                color: "#ffffff",
                "border-color": "#ffffff",
              },
            },
          },
        },
      });
    });
  }
})();

// Shopify 1000 Credits
(function () {
  var scriptURL =
    "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
  if (window.ShopifyBuy) {
    if (window.ShopifyBuy.UI) {
      ShopifyBuyInit();
    } else {
      loadScript();
    }
  } else {
    loadScript();
  }
  function loadScript() {
    var script = document.createElement("script");
    script.async = true;
    script.src = scriptURL;
    (
      document.getElementsByTagName("head")[0] ||
      document.getElementsByTagName("body")[0]
    ).appendChild(script);
    script.onload = ShopifyBuyInit;
  }
  function ShopifyBuyInit() {
    // eslint-disable-next-line no-undef
    var client = ShopifyBuy.buildClient({
      domain: "looking-glass-factory-2.myshopify.com",
      storefrontAccessToken: "5a074389197c0abf2be0e218703fcc18",
    });
    // eslint-disable-next-line no-undef
    ShopifyBuy.UI.onReady(client).then(function (ui) {
      ui.createComponent("product", {
        id: "6559203229785",
        node: document.getElementById("product-component-1660252932684"),
        moneyFormat: "%24%7B%7Bamount%7D%7D",
        options: {
          product: {
            styles: {
              product: {
                "@media (min-width: 601px)": {
                  "max-width": "calc(25% - 20px)",
                  "margin-left": "20px",
                  "margin-bottom": "50px",
                },
              },
              title: {
                color: "#ffffff",
              },
              button: {
                ":hover": {
                  "background-color": "#5353e6",
                },
                "background-color": "#5c5cff",
                ":focus": {
                  "background-color": "#5353e6",
                },
              },
              price: {
                color: "#fdfcfc",
              },
              compareAt: {
                color: "#fdfcfc",
              },
              unitPrice: {
                color: "#fdfcfc",
              },
            },
            text: {
              button: "Add to cart",
            },
          },
          productSet: {
            styles: {
              products: {
                "@media (min-width: 601px)": {
                  "margin-left": "-20px",
                },
              },
            },
          },
          modalProduct: {
            contents: {
              img: false,
              imgWithCarousel: true,
              button: false,
              buttonWithQuantity: true,
            },
            styles: {
              product: {
                "@media (min-width: 601px)": {
                  "max-width": "100%",
                  "margin-left": "0px",
                  "margin-bottom": "0px",
                },
              },
              button: {
                ":hover": {
                  "background-color": "#5353e6",
                },
                "background-color": "#5c5cff",
                ":focus": {
                  "background-color": "#5353e6",
                },
              },
              title: {
                "font-family": "Helvetica Neue, sans-serif",
                "font-weight": "bold",
                "font-size": "26px",
                color: "#4c4c4c",
              },
              price: {
                "font-family": "Helvetica Neue, sans-serif",
                "font-weight": "normal",
                "font-size": "18px",
                color: "#4c4c4c",
              },
              compareAt: {
                "font-family": "Helvetica Neue, sans-serif",
                "font-weight": "normal",
                "font-size": "15.299999999999999px",
                color: "#4c4c4c",
              },
              unitPrice: {
                "font-family": "Helvetica Neue, sans-serif",
                "font-weight": "normal",
                "font-size": "15.299999999999999px",
                color: "#4c4c4c",
              },
            },
            text: {
              button: "Add to cart",
            },
          },
          option: {},
          cart: {
            styles: {
              button: {
                ":hover": {
                  "background-color": "#5353e6",
                },
                "background-color": "#5c5cff",
                ":focus": {
                  "background-color": "#5353e6",
                },
              },
              title: {
                color: "#ffffff",
              },
              header: {
                color: "#ffffff",
              },
              lineItems: {
                color: "#ffffff",
              },
              subtotalText: {
                color: "#ffffff",
              },
              subtotal: {
                color: "#ffffff",
              },
              notice: {
                color: "#ffffff",
              },
              currency: {
                color: "#ffffff",
              },
              close: {
                color: "#ffffff",
                ":hover": {
                  color: "#ffffff",
                },
              },
              empty: {
                color: "#ffffff",
              },
              noteDescription: {
                color: "#ffffff",
              },
              discountText: {
                color: "#ffffff",
              },
              discountIcon: {
                fill: "#ffffff",
              },
              discountAmount: {
                color: "#ffffff",
              },
              cart: {
                "background-color": "#212121",
              },
              footer: {
                "background-color": "#212121",
              },
            },
            text: {
              total: "Subtotal",
              button: "Checkout",
            },
            popup: false,
          },
          toggle: {
            styles: {
              toggle: {
                "background-color": "#5c5cff",
                ":hover": {
                  "background-color": "#5353e6",
                },
                ":focus": {
                  "background-color": "#5353e6",
                },
              },
            },
          },
          lineItem: {
            styles: {
              variantTitle: {
                color: "#ffffff",
              },
              title: {
                color: "#ffffff",
              },
              price: {
                color: "#ffffff",
              },
              fullPrice: {
                color: "#ffffff",
              },
              discount: {
                color: "#ffffff",
              },
              discountIcon: {
                fill: "#ffffff",
              },
              quantity: {
                color: "#ffffff",
              },
              quantityIncrement: {
                color: "#ffffff",
                "border-color": "#ffffff",
              },
              quantityDecrement: {
                color: "#ffffff",
                "border-color": "#ffffff",
              },
              quantityInput: {
                color: "#ffffff",
                "border-color": "#ffffff",
              },
            },
          },
        },
      });
    });
  }
})();
</script>

<style src="../assets/tailwind.css">
@import url("https://fonts.googleapis.com/css?family=IBM Plex Sans");

p a {
  text-decoration: underline;
}

.conversions {
  table-layout: fixed;
  overflow-wrap: break-word;
  width: 100%;
}

.filepond--root {
  margin: 0 !important;
}

.filepond--root .filepond--drop-label {
  min-height: 550px !important;
  background: grey !important;
}

/* // accordion styling */

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.active,
.accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}
</style>

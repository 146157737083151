import App from "./App.vue";
import { createApp } from "vue";
import { createStore } from 'vuex'
import { createRouter } from "./router";
import { createAuth0 } from "@auth0/auth0-vue";
import hljs from "vue3-highlightjs";
import "highlight.js/styles/github.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink, faUser, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import mitt from 'mitt'
import './assets/tailwind.css'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

const emitter = mitt()

const audience = process.env.VUE_APP_AUTHO_AUDIENCE
const domain = process.env.VUE_APP_AUTHO_DOMAIN
const client_id = process.env.VUE_APP_AUTHO_CLIENT_ID

const store = createStore({
  state () {
    return {
      isLoggedIn: false,
      accessTokenStore: '',
      userDetails: {},
      apiCallCount: 0,
      convertedUrls: ['init']

    }
  },
  mutations: { // synchronous
    isLoggedIn (state, payload) {
      state.isLoggedIn = payload
    },
    isLoggedOut (state, payload) {
      state.isLoggedIn = payload
    },
    updateAccessToken(state, payload) {
      state.accessTokenStore = payload
    },
    updateUserDetails(state, payload) {
      state.userDetails = payload
    },
    updateApiCallCount(state, payload) {
      state.apiCallCount = payload
    },
    appendToConvertedUrls(state, payload) {
      state.convertedUrls.push(payload)
    },
    clearConvertedUrlsArr(state, payload) {
      state.convertedUrls = ['init']
    }
  },
  actions: { // asynchronous
    isLoggedIn (state, payload) {
      state.commit('isLoggedIn', payload)
    },
    isLoggedOut (state, payload) {
      state.commit('isLoggedIn', payload)
    },
    updateAccessToken(state, payload) {
      state.commit('updateAccessToken', payload)
    },
    updateUserDetails(state, payload) {
      state.commit('updateUserDetails', payload);
    },
    updateApiCallCount(state, payload) {
      state.commit('updateApiCallCount', payload)
    },
    appendToConvertedUrls(state, payload) {
      state.commit('appendToConvertedUrls', payload)
    }
  },
})

const app = createApp(App);
const router = createRouter(app);

if (process.env.VUE_APP_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_PUBLIC_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", /lookingglass\.vercel\.app/, /2dto3d\.lookingglassfactory\.com/],
      }),
    ],
    logErrors: true,
    tracesSampleRate: 0.2,
  });
}
else {
  console.warn("Sentry is disabled")
}

library.add(faLink, faUser, faPowerOff);

app
  .use(hljs)
  .use(router)
  .use(store)
  .use(
    createAuth0({
      domain,
      client_id,
      redirect_uri: window.location.origin,
      audience
    })
  )
  .provide('emitter', emitter)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");